.collapse-section {
    padding: 70px 30px 108px;

    .collapse-section-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        display: grid;
        grid-gap: 0 70px;

        @media (max-width: 992px) {
            display: block;
        }

        .collapse-item {
            position: relative;
            height: fit-content;
            border-top: 1px solid rgba(25, 26, 37, 0.1);

            &.bordered {
                border: 1px solid rgba(144,144,167,0.25);
                margin-bottom: 10px;

                &:hover {
                  background-color: rgba(61,123,250,0.05);
                }
            }

            &.open {
                .collapse-item-content {
                    opacity : 1;
                    height: auto;
                    transition: all 0.5s ease-in-out;

                    img {
                        padding-bottom: 20px;
                    }

                    .btn-black {
                      margin-bottom: 23px;
                      margin-top: 20px;
                    }
                }

                .collapse-item-button {
                    >div {
                        opacity: 0;

                        &:last-child {
                            opacity: 1;
                        }
                    }

                    > svg {
                      transform: rotate(180deg);
                    }
                }
            }

            &:last-child {
                border-bottom: 1px solid rgba(25, 26, 37, 0.1);
            }

            .collapse-item-header {
                position : relative;
                padding-right: 55px;
                padding-left: 26px;
                cursor: pointer;

                @media (max-width: 992px) {
                    padding-right: 40px;
                }

                h6 {
                    padding : 23px 0;
                    color : #3D7BFA;
                    font-family : 'Berlin Sans FB Regular', sans-serif;
                    font-size : 20px;
                    letter-spacing: 0;
                    line-height : 22px;
                }
            }

            .collapse-item-content {
                height : 0;
                padding: 0 26px;
                overflow: hidden;
                transition: all 0.5s;

                img {
                    width: 100%;
                    height: 100%;
                    max-height: 202px;
                    object-fit: cover;
                }

                p {
                    color : #191A25;
                    font-family : 'Lato', sans-serif;
                    font-size : 16px;
                    letter-spacing: 0;
                    line-height : 27px;
                }
            }

            .collapse-item-button {
                position: absolute;
                right : 25px;
                top : 0;
                bottom: 0;
                outline : none;

                @media (max-width: 992px) {
                    right: 0;
                }

                > svg {
                  width: 20px;
                  height: 20px;
                  transition: ease-in-out all 0.4s;
                }

                >div {
                    height: 21px;
                    width : 3px;
                    background: #000;
                    position: relative;
                    left: 9px;
                    opacity : 1;
                    transition: opacity 0.4s ease-in-out;

                    &:last-child {
                        width : 21px;
                        height: 3px;
                        background: #000;
                        position: relative;
                        left: 0;
                        bottom: 12px;
                    }
                }
            }
        }
    }
}
